import { VyneUserThemes } from '@vynedental/design-system'
import { ConfigProvider } from 'antd'

// theme will be set on main layouts
export const setTheme = (appTheme: VyneUserThemes) => {
  const root: HTMLElement = document.querySelector(':root')

  // themeColor is for ant design config
  let themeColor: string
  switch (appTheme) {
    case VyneUserThemes.dentalIntelligenceTheme:
      themeColor = '#0060C6'
      break
    case VyneUserThemes.remoteLiteTheme:
      themeColor = '#307596'
      break
    case VyneUserThemes.trellisTheme:
    default:
      themeColor = '#91C73D'
      break
  }

  ConfigProvider.config({
    theme: {
      primaryColor: themeColor,
    },
  })

  // this is for our internal css variables
  // color is handled with hsl
  if (appTheme === VyneUserThemes.dentalIntelligenceTheme) {
    root?.style?.setProperty('--color', '211, 100%')
    root?.style?.setProperty('--lightness', '39%')
  } else if (appTheme === VyneUserThemes.remoteLiteTheme) {
    root?.style?.setProperty('--color', '199, 52%')
    root?.style?.setProperty('--lightness', '39%')
  } else if (appTheme === VyneUserThemes.trellisTheme) {
    root?.style?.setProperty('--color', '83, 55%')
    root?.style?.setProperty('--lightness', '51%')
  }
}
